import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React, {createRef, useEffect} from "react";
import { LinkButton, LinkButtonOutline } from "../linkButton/linkButton";
import * as styles from "./home-hero.module.scss";
import JSONData from "../../../content/mainPageHeroData.json";
import NearFoundationSVG from "../../svg/clients/nearFoundation.svg";
import GithubSVG from "../../svg/clients/github.svg";
import MedableSVG from "../../svg/clients/medableSVG.svg"
import ForgerockSVG from "../../svg/clients/forgerockSVG.svg"
import lottie from 'lottie-web';
import animationData from "../../asset-animation/pageHeroAnimation.json";

export default function HomeHero() {
    const data = useStaticQuery(graphql`{
        image1: file(relativePath: {eq: "home-hero/image-1.png"}) {
            childImageSharp {
                gatsbyImageData(quality: 70, layout: FULL_WIDTH)
            }
        },
        image2: file(relativePath: {eq: "home-hero/image-2.png"}) {
            childImageSharp {
                gatsbyImageData(quality: 70, layout: FULL_WIDTH)
            }
        },
        image3: file(relativePath: {eq: "home-hero/image-3.png"}) {
            childImageSharp {
                gatsbyImageData(quality: 70, layout: FULL_WIDTH)
            }
        },
        image4: file(relativePath: {eq: "home-hero/image-4.png"}) {
            childImageSharp {
                gatsbyImageData(quality: 70, layout: FULL_WIDTH)
            }
        }
        tapdaq: file(relativePath: {eq: "home-hero/clients/tapdaq.png"}) {
            childImageSharp {
                gatsbyImageData(quality: 70, width:116)
            }
        }
    }`)

    const animationContainer = createRef<any>();

    useEffect(() => {
        const animation = lottie.loadAnimation({
            container: animationContainer.current,
            renderer: "svg",
            loop: true,
            autoplay: true,
            animationData: animationData
        });
        return () => animation.destroy();
    }, []);

    return <div className={styles.wrapper}>
        <div className={styles.card}>
            <div className={styles.animationContainer}>
                <div className={styles.backgroundAnimation} ref={animationContainer}/>
            </div>
            <span className={styles.title}>
                {JSONData.title}
            </span>
            <span className={styles.subtext}>
                {JSONData.text}
            </span>
            <span className={styles.buttons}>
                <LinkButton to="/services">
                    What we do
                </LinkButton>
                <LinkButtonOutline to="/aboutUs">
                    About us
                </LinkButtonOutline>
            </span>
            <span className={styles.imagesPlaceholder}>
                    <div className={styles.imageWrapper}>
                        <GatsbyImage
                            image={data.image1.childImageSharp.gatsbyImageData}
                            alt="person"
                            aria-hidden="true"
                            className={`${styles.image} ${styles.image1}`} />
                    </div>
                    <div className={styles.imageWrapper}>
                        <GatsbyImage
                            image={data.image2.childImageSharp.gatsbyImageData}
                            alt="person"
                            aria-hidden="true"
                            className={`${styles.image} ${styles.image2}`} />
                    </div>
                    <div className={styles.imageWrapper}>
                        <GatsbyImage
                            image={data.image3.childImageSharp.gatsbyImageData}
                            alt="person"
                            aria-hidden="true"
                            className={`${styles.image} ${styles.image3}`} />
                    </div>
                    <div className={styles.imageWrapper}>
                        <GatsbyImage
                            image={data.image4.childImageSharp.gatsbyImageData}
                            alt="person"
                            aria-hidden="true"
                            className={`${styles.image} ${styles.image4}`} />
                    </div>
            </span>
        </div>
        <div className={styles.clients}>
            <span className={styles.clientsTitle}>Placing expert talent in some of the biggest names in tech</span>
            <div className={styles.images}>
                <GatsbyImage
                    image={data.tapdaq.childImageSharp.gatsbyImageData}
                    alt="tapdaq logo"
                    className={styles.clientLogo}
                    objectFit="contain"
                />
                <NearFoundationSVG className={styles.clientLogo} />
                <GithubSVG className={styles.clientLogo} />
                <ForgerockSVG className={styles.clientLogo}/>
                <MedableSVG className={styles.clientLogo}/>
            </div>
        </div>
    </div>
}
